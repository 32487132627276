import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    user: false,
    user_info: {},
    admin: false
  },
  getters: {
  },
  mutations: {
    authUser(state, elem){
      state.user = true;
      state.user_info = elem;
      if(state.user_info.favourites !== 'null'){
        state.user_info.favourites = JSON.parse(state.user_info.favourites)
      }
    },
    exitUser(state){
      state.user = false;
      state.user_info = {};
    },
    updateName(state, elem){
      state.user_info.name = elem
    },
    updateEmail(state, elem){
      state.user_info.email = elem
    },
    updatePhone(state, elem){
      state.user_info.phone = elem
    },
    updatePassword(state, elem){
      state.user_info.password = elem
    },
    makeDeposit(state){
      state.user_info.deposit = 1
    },
    outDeposit(state){
      state.user_info.deposit = 0
    },
    addToFavourites(state, elem){
      if(state.user_info.favourites == 'null'){
        state.user_info.favourites = []
      }
      state.user_info.favourites.push(elem)
    },
    removeFromFavourites(state, elem){
      state.user_info.favourites = state.user_info.favourites.filter((id) => id !== elem)
    },
    adminEnter(state){
      state.admin = true;
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState(
      {
        paths: ['user', 'user_info', 'admin']
      }
    )
  ]
})
