<template>
    <footer>
      <div class="container">
        <a name="contacts"></a>
        <div class="row">
        <div class="col-sm-4 col-12">
          <div class="d-flex mt-3">
            <a href="/" class="logo" style="height: fit-content;">
            <img src="../assets/logo.jpg" alt="" style="height: 3em; width: auto;">
          </a>
          <p class="text-start ms-3 my-auto" style="line-height: 1;">©2023 Мото Аукционы России d2auction.ru</p>
          </div>
          
          <p class="mt-2" style="line-height: 1; width: fit-content; color: #7a7a7a;">г. Москва, Переведеновский переулок, 18c3</p>
          
          <!-- <p class="text-start mb-sm-auto" style="line-height: 1;"><a href="#" style="font-size: 14px; color: #7a7a7a;">Политика конфиденциальности</a></p> -->
        </div>
        <div class="col-sm-4 col-12">
          <ul class="text-center py-4">
            <li class="my-2"><a href="/#about_us" style="color: #7a7a7a;">О нас</a></li>
            <li class="my-2"><a href="/bids" style="color: #7a7a7a;">Аукционы</a></li> 
            <li class="my-2"><a href="/bids?buy_now" style="color: #7a7a7a;">Купить сейчас</a></li>
            <li v-if="$store.state.user"><a href="/lk" style="color: #7a7a7a;">Личный кабинет</a></li>
            <li v-else><a data-bs-toggle="modal" data-bs-target="#signInModal" style="color: #7a7a7a;">Личный кабинет</a></li>
          </ul>
        </div>
        <div class="col-sm-4 col-12">
          <ul class="text-sm-end text-center py-4">
            <li class="my-2"><a href="tel:+79852206399" style="color: #7a7a7a;"><i class="bi bi-telephone-forward-fill"></i> +7 (985) 220 63 99</a></li>
            <li class="my-2"><a href="tel:+79153453244" style="color: #7a7a7a;"><i class="bi bi-telephone-forward-fill"></i> +7 (915) 345 32 44</a></li>
            <li class="my-2"><a href="mailto:d2@d2auction.ru" style="color: #7a7a7a;"><i class="fa fa-envelope"></i> d2@d2auction.ru</a></li>
            <li class="my-2 d-flex justify-content-center justify-content-sm-end">
              <a href="https://t.me/d2auction" class="p-2 me-1" style="background-color: #fff; border-radius: 10px;"><img src="../assets/logo_tg.png" alt="" style="width: 1.5em; height: 1.5em;"></a>
              <a href="https://www.instagram.com/d2auction?igsh=YzVkODRmOTdmMw%3D%3D&utm_source=qr" class="p-2 ms-1" style="background-color: #fff; border-radius: 10px;"><img src="../assets/logo_inst.png" alt="" style="width: 1.5em; height: 1.5em;"></a>
            </li>
          </ul>
          
          
          
        </div>
    </div>
      </div>
    
        
  </footer>
</template>

<script>
export default{}
</script>