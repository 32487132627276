<template>
  <pre-loader />
  <main-menu />
  <modal-lk />
  <router-view />
  <main-footer />
</template>

<style lang="scss">
@import url(./assets/css/animate.css);
// @import url(./assets/css/flex-slider.css);
// @import url(./assets/css/fontawesome.css);
// @import url(./assets/css/owl.css);
@import url(./assets/css/templatemo-villa-agency.css);
</style>

<script>
import PreLoader from './components/PreLoader.vue'
import MainMenu from './components/MainMenu.vue'
import MainFooter from './components/MainFooter.vue'
import ModalLk from './components/ModalLk.vue'

export default{
  components: {
    PreLoader, MainMenu, MainFooter, ModalLk
  },
  mounted(){
    // Page loading animation
$(window).on('load', function() {
	
$('#js-preloader').addClass('loaded');

});


$(window).scroll(function() {
var scroll = $(window).scrollTop();
var box = $('.header-text').height();
var header = $('header').height();

if (scroll >= box - header) {
$("header").addClass("background-header");
} else {
$("header").removeClass("background-header");
}
})

$('.owl-banner').owlCarousel({
center: true,
items:1,
loop:true,
nav: true,
dots:true,
navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
margin:30,
responsive:{
992:{
    items:1
},
1200:{
items:1
}
}
});

var width = $(window).width();
$(window).resize(function() {
if (width > 767 && $(window).width() < 767) {
location.reload();
}
else if (width < 767 && $(window).width() > 767) {
location.reload();
}
})

const elem = document.querySelector('.properties-box');
const filtersElem = document.querySelector('.properties-filter');
if (elem) {
const rdn_events_list = new Isotope(elem, {
itemSelector: '.properties-items',
layoutMode: 'masonry'
});
if (filtersElem) {
filtersElem.addEventListener('click', function(event) {
if (!matchesSelector(event.target, 'a')) {
  return;
}
const filterValue = event.target.getAttribute('data-filter');
rdn_events_list.arrange({
  filter: filterValue
});
filtersElem.querySelector('.is_active').classList.remove('is_active');
event.target.classList.add('is_active');
event.preventDefault();
});
}
}


// Menu Dropdown Toggle
if($('.menu-trigger').length){
$(".menu-trigger").on('click', function() {	
$(this).toggleClass('active');
$('.header-area .nav').slideToggle(200);
});
}


// Menu elevator animation
$('.scroll-to-section a[href*=\\#]:not([href=\\#])').on('click', function() {
if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
var target = $(this.hash);
target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
if (target.length) {
var width = $(window).width();
if(width < 991) {
  $('.menu-trigger').removeClass('active');
  $('.header-area .nav').slideUp(200);	
}				
$('html,body').animate({
  scrollTop: (target.offset().top) - 80
}, 700);
return false;
}
}
});


// Page loading animation
$(window).on('load', function() {
if($('.cover').length){
$('.cover').parallax({
imageSrc: $('.cover').data('image'),
zIndex: '1'
});
}

$("#preloader").animate({
'opacity': '0'
}, 600, function(){
setTimeout(function(){
$("#preloader").css("visibility", "hidden").fadeOut();
}, 300);
});
});
  }
}
</script>