<template>
    <div class="container my-5" style="min-height: 60vh;">
        <h4 class="text-center">Введите код:</h4>
        <div class="admin-enter-form d-lg-flex d-none w-25 mx-auto mt-4">
            <input type="password" class="form-control me-2" v-model="enterCode">
            <button class="btn btn-success">Войти</button>
        </div>
        <div class="admin-enter-form d-sm-flex d-none d-lg-none w-50 mx-auto mt-4">
            <input type="password" class="form-control me-2" v-model="enterCode">
            <button class="btn btn-success">Войти</button>
        </div>
        <div class="admin-enter-form d-sm-none d-flex w-100 mx-auto mt-4">
            <input type="password" class="form-control me-2" v-model="enterCode">
            <button class="btn btn-success" @click="enterAdminTest">Войти</button>
        </div>
    </div> 
</template>

<script>
import axios from 'axios'
export default{
    data(){
        return{
            enterCode: ''
        }
    },
    methods: {
        enterAdmin(){
            axios.post('https://d2auction.ru/enterAdmin.php', {code: this.enterAdmin}).then((response)=>{
                if(response.data == 'успешно'){
                    this.$store.commit('adminEnter')
                }
                else{
                    alert('Неверный код!')
                }
            })
        },

        enterAdminTest(){
            this.$store.commit('adminEnter')
        }
    }
}
</script>