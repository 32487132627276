<template>

<div class="sub-header">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-8">
          <ul class="info">
            <li><i class="fa fa-envelope"></i>d2@d2auction.ru</li>
            <li><i class="fa fa-map"></i>г. Москва, Переведеновский переулок, 18c3</li>
          </ul>
        </div>
        <ul class="col-lg-4 col-md-4 text-end info">
          <li><a href="tel:+79852206399" style="color: #7a7a7a;"><i class="bi bi-telephone-forward-fill"></i> +7 (985) 220 63 99</a></li>
        </ul>
      </div>
    </div>
  </div>

  <!-- ***** Header Area Start ***** -->
  <header class="header-area header-sticky">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <nav class="main-nav">
                    <!-- ***** Logo Start ***** -->
                    <a href="/" class="logo mt-md-3 mt-2" style="height: fit-content; width: fit-content;">
                        <img src="../assets/logo.jpg" alt="" style="height: 4em;  width: auto;">
                    </a>
                    <!-- ***** Logo End ***** -->
                    <!-- ***** Menu Start ***** -->
                    <ul class="nav">
                      <li><a href="/#about_us">О нас</a></li>
                      <!-- <li><a href="property-details.html">Как это работает</a></li> -->
                      <li><a href="/bids">Аукционы</a></li>
                      <li v-if="this.$store.state.user_info.deposit == 1"><a href="/bids?history">Купить сейчас</a></li>
                      <li><a href="#contacts">Контакты</a></li>
                      <li v-if="$store.state.user"><a href="/lk" style="cursor: pointer;"><i class="bi bi-person-circle d-none"></i> Личный кабинет</a></li>
                      <li v-else><a data-bs-toggle="modal" data-bs-target="#signInModal" style="cursor: pointer;"><i class="bi bi-person-circle d-none"></i> Личный кабинет</a></li>
                      <li></li>
                  </ul>   
                    <a class='menu-trigger'>
                        <span>Меню</span>
                    </a>
                    <!-- ***** Menu End ***** -->
                </nav>
            </div>
        </div>
    </div>
  </header>
  <!-- ***** Header Area End ***** -->
</template>

<script>
export default{

}
</script>