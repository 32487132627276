<template>
  <div class="modal fade px-0" :id="'openBidImg'+id" tabindex="-1" aria-labelledby="openBidImgLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen modal-dialog-centered" style="height: fit-content;">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div :id="'itemSliderModalBid'+id" class="carousel carousel-dark slide item-slider" data-bs-touch="true" data-bs-interval="false">

          <div class="carousel-inner">
            <div class="carousel-item" :class="{'active': index === openImgSrc}" v-for="(img, index) in JSON.parse(bid_info.images)" >
              <img :src="'https://d2auction.ru/'+img" class="d-block w-100" style="aspect-ratio: 16 / 9; object-fit: contain; max-height: 90vh;">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" :data-bs-target="'#itemSliderModalBid'+id"  data-bs-slide="prev" >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Предыдущий</span>
          </button>
          <button class="carousel-control-next" type="button" :data-bs-target="'#itemSliderModalBid'+id" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Следующий</span>
          </button>
          </div>

      </div>
    </div>
  </div>

    <div class="page-heading header-text mt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!-- <span class="breadcrumb"><a href="#">Главная</a>  /  <a href="#">Аукционы</a>  /  {{ bid_info.title }}</span> -->
        </div>
      </div>
    </div>
  </div>

  <div class="single-property section mt-0">
    <div class="container">
      <div class="row">
        <!-- <div class="col-lg-2" v-if="bid_info.type == 'Запланированные'"></div> -->
        <div class="col-lg-8">
          <div class="main-image mt-5">
            <div :id="'itemSliderBid'+id" class="carousel slide item-slider" data-bs-touch="true" data-bs-interval="false" >
              <div class="carousel-inner" style="border-radius: 10px;">
                <div class="carousel-item" style="border-radius: 10px;" v-for="(img, index) in JSON.parse(bid_info.images)" :class="{'active': index==0}">
                  <img :src="'../../'+img" @click="openImgGetSrc(index)" data-bs-toggle="modal" :data-bs-target="'#openBidImg'+id" class="d-block w-100"  style="aspect-ratio: 16 / 9; object-fit: cover;">
                </div>
              </div>
              <button class="carousel-control-prev" type="button" :data-bs-target="'#itemSliderBid'+id" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Предыдущий</span>
              </button>
              <button class="carousel-control-next" type="button" :data-bs-target="'#itemSliderBid'+id" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Следующий</span>
              </button>
              </div>
          </div>
          <div class="main-content">
            <span class="category" :style="{backgroundColor: typeColor}">{{ bid_info.type }}</span>
            <div class="d-flex"><h4 class="pb-4 mb-4">{{ bid_info.title }}</h4>
                  <div v-if="$store.state.user == true" class="my-auto pb-4 ms-2">
                    <a v-if="$store.state.user_info.favourites.includes(id)" @click="removeFromFavorites" style="cursor: pointer; font-size: 1em;"><i class="bi bi-heart-fill" style="color: #DC143C"></i></a>
                    <a v-else @click="addToFavorites" style="cursor: pointer; font-size: 1em;"><i class="bi bi-heart" style="color: #DC143C"></i></a>
                  </div>
            </div>
            <p class="mt-0" style="white-space: pre-wrap;">
              {{ bid_info.description }}
            </p>
          </div> 
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Технические характеристики
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <ul>
                    <li v-for="(value, key) in JSON.parse(bid_info.params)" :key="key"><b>{{ key }}:</b> {{ value }}</li>
                  </ul>
                  <br>
                  <p>Дополнительная информация:</p>
                  <p style="white-space: pre-wrap;">{{ bid_info.extrainfo }}</p>
                  <div v-if="bid_info.pdf_file !== '' && $store.state.user == true">
                    <p v-if="$store.state.user_info.deposit == 1">Файл с детальными фото: <a :href="'https://d2auction.ru/'+bid_info.pdf_file">Открыть файл</a></p>
                    <p v-else>Файл с детальной информацией недоступен без депозита</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  История ставок
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body" v-if="bid_info.type=='Запланированные' || bid_info.bets == null">
                  <p>История ставок недоступна</p>
                </div>
                <div class="accordion-body" v-if="bid_info.type!=='Запланированные' && bid_info.bets!== null"> 
                  <p class="mb-2">Количество ставок: {{ JSON.parse(bid_info.bets).length }}</p>
                  <ul v-if="$store.state.user == true"> 
                    <li v-if="$store.state.user_info.deposit == true" v-for="bet in JSON.parse(bid_info.bets)" class="mb-2">{{ bet.user }} &bull; <b v-if="bid_info.currency == 'рубль'">₽</b><b v-if="bid_info.currency == 'доллар'">$</b><b>{{ bet.amount }}</b></li>
                    <li v-else><p>Пополните депозит чтобы увидеть историю ставок</p></li>
                  </ul>
                  <p v-else>Войдите в личный кабинет чтоб увидеть историю ставок</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-5">
          <div class="info-table py-4" v-if="bid_info.type=='Актуальные'">
            <ul>
              <li class="mb-4 pb-4" v-if="bid_info.currency!==''">
                <img src="../assets/bid_icon_01.svg" alt="" style="max-width: 30px;">
                <h4 v-if="bid_info.currency=='рубль'">₽{{ Number(bid_info.price).toLocaleString() }}<br><span>Текущая ставка</span></h4>
                <h4 v-if="bid_info.currency=='доллар'">${{ Number(bid_info.price).toLocaleString() }}<br><span>Текущая ставка</span></h4>
              </li>
              <li class="mb-4 pb-4" style="display: flex;">
                <img src="../assets/bid_icon_02.svg" class="my-auto" alt="" style="max-width: 30px;">
                <h4 class="my-auto" v-html="timer"></h4>
              </li>
              <li class="mb-4 pb-4">
                <img src="../assets/bid_icon_03.svg" alt="" style="max-width: 30px;">
                <h4>{{ dateStr }}<br><span>Заканчивается</span></h4>
              </li>
              <li class="nav-item mb-4">
                <!-- <img src="../assets/bid_icon_04.svg" alt="" style="max-width: 30px;"> -->
                  <fieldset class="w-75 mx-auto">
                    <div class="d-flex">
                      <input type="text" class="form-control" v-model="makeBetAmount" :placeholder="Number(bid_info.price)+Number(bid_info.minStep)">
                      <span class="my-auto ms-2" style="font-size: 1.2em;" v-if="bid_info.currency == 'рубль'">₽</span>
                      <span class="my-auto ms-2" style="font-size: 1.2em;" v-if="bid_info.currency == 'доллар'">$</span>
                    </div>
                    <button class="btn btn-success btn-md mt-2" @click="makeBet" v-if="$store.state.user_info.deposit">Сделать ставку</button>
                    <button class="btn btn-success btn-md mt-2" onclick="alert('Необходимо войти в личный кабинет и внести депозит')" v-else>Сделать ставку</button>
                </fieldset>
                
              </li>
            </ul>
          </div>

          <div class="info-table pt-4 py-0" v-if="bid_info.type=='Прошедшие'">
            <ul>
              <li class="mb-4 pb-4" v-if="bid_info.currency!==''">
                <img src="../assets/bid_icon_01.svg" alt="" style="max-width: 30px;">
                <h4 v-if="bid_info.currency=='рубль'">₽{{ Number(bid_info.price).toLocaleString() }}<br><span v-if="bid_info.buynow == 1">Цена выкупа</span><span v-else>Последняя ставка</span></h4>
                <h4 v-if="bid_info.currency=='доллар'">${{ Number(bid_info.price).toLocaleString() }}<br><span v-if="bid_info.buynow == 1">Цена выкупа</span><span v-else>Последняя ставка</span></h4>
              </li>
              <li class="mb-4 pb-4" v-if="bid_info.sold == '0'">
                <img src="../assets/bid_icon_03.svg" alt="" style="max-width: 30px;">
                <h4>{{ dateStr }}<br><span>Закончился</span></h4>
              </li>
              <li class="mb-0 pb-4" v-else>
                <img src="../assets/bid_icon_03.svg" alt="" style="max-width: 30px;">
                <h4>{{ dateStr }}<br><span>Закончился</span></h4>
              </li>
              <li class="nav-item mb-4 pb-4 d-flex" v-if="bid_info.buynow == 1 && bid_info.sold !== '1'">
                <!-- <img src="../assets/bid_icon_04.svg" alt="" style="max-width: 52px;"> -->
                    <button class="btn btn-success btn-md mt-2 mx-auto" @click="buyNow" v-if="$store.state.user">Купить сейчас</button>
                    <button class="btn btn-success btn-md mt-2 mx-auto" onclick="alert('Необходимо войти в личный кабинет')" v-else>Купить сейчас</button>
              </li>
              <li class="nav-item mb-4 pb-0 d-flex" v-if="bid_info.sold == '1'">
                <img src="../assets/sold_horizontal.png" class="mx-auto w-50" alt="">
                    
              </li>
            </ul>
          </div>

          <!-- <div class="info-table py-4" v-if="bid_info.type=='Запланированные'">
            <ul>
              <li class="mb-4 pb-4" v-if="bid_info.currency!==''">
                <img src="../assets/bid_icon_01.svg" alt="" style="max-width: 30px;">
                <h4 v-if="bid_info.currency=='рубль'">₽{{ Number(bid_info.price).toLocaleString() }}<br><span>Цена выкупа</span></h4>
                <h4 v-if="bid_info.currency=='доллар'">${{ Number(bid_info.price).toLocaleString() }}<br><span>Цена выкупа</span></h4>
              </li>
              <li class="mb-4 pb-4" style="display: flex;">
                <img src="../assets/bid_icon_02.svg" class="my-auto" alt="" style="max-width: 30px;">
                <h4 class="my-auto">{{ timer }}</h4>
              </li>
              <li class="mb-4 pb-4">
                <img src="../assets/bid_icon_03.svg" alt="" style="max-width: 30px;">
                <h4>{{ dateStr }}<br><span>Начинается</span></h4>
              </li>
              <li class="nav-item mb-4 d-flex" v-if="bid_info.buynow == 1">
                <img src="../assets/bid_icon_04.svg" alt="" style="max-width: 52px;">
                    <button class="btn btn-success btn-md mt-2 mx-auto" @click="buyNow" v-if="$store.state.user">Купить сейчас</button>
                    <button class="btn btn-success btn-md mt-2 mx-auto" onclick="alert('Необходимо войти в личный кабинет')" v-else>Купить сейчас</button>
              </li>
            </ul>
          </div> -->

        </div>
      </div>
    </div>
  </div>

  <div class="properties section">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 offset-lg-4">
          <div class="section-heading text-center">
            <h6>Смотрите также</h6>
          </div>
        </div>
      </div>
      
      <div class="row">
      <bid-preview v-for="bid in bids_actual"
        :type = bid.type
        :title = bid.title
        :price = bid.price
        :description = '[JSON.parse(bid.params)["Объем"], JSON.parse(bid.params)["Год"], JSON.parse(bid.params)["Пробег"], JSON.parse(bid.params)["Город"]]'
        :images = JSON.parse(bid.images)
        :id = bid.id 
        :dateEnd = bid.dateEnd 
        :dateStart = bid.dateStart
        :currency = bid.currency />
      </div>
    </div>
  </div>
</template>

<script>
import BidPreview from '../components/BidPreview.vue'
import axios from 'axios'
export default{
  props: {
    id: Number,
  },

  components:{
    BidPreview
  },

  data(){
    return{
      timer: '',
      bid_info: {},
      typeColor: '',
      dateStr : '',
      makeBetAmount: '',
      openImgSrc: 0
    }
  },

  methods:{
    loadBid(){
      axios.post('https://d2auction.ru/api.php', {
      action: 'get_bid',
      id: this.$props.id
    }).then((response)=>{
      console.log(response)
      this.bid_info = response.data
      
        if(this.bid_info.type == 'Актуальные'){
          this.bid_info.dateEnd = new Date(this.bid_info.dateEnd)
          this.dateStr = `${this.bid_info.dateEnd.getDate()}.${this.bid_info.dateEnd.getMonth()+1}.${this.bid_info.dateEnd.getFullYear()}г`

          if(this.bid_info.bets !== null){
            this.bid_info.price = JSON.parse(this.bid_info.bets)[0].amount
            console.log(this.bid_info.price)
          }
          else{
            this.bid_info.price = this.bid_info.betStart
          }
        }

        else if ( this.bid_info.type == 'Прошедшие') {
          this.bid_info.dateEnd = new Date(this.bid_info.dateEnd)
          this.dateStr = `${addZero(Number(this.bid_info.dateEnd.getDate()))}.${addZero(Number(this.bid_info.dateEnd.getMonth()+1))}.${this.bid_info.dateEnd.getFullYear()}г`
          function addZero(num) {
            if (num <= 9) {
              num = '0' + num;
            }
            
            return num;
          }

          if(this.bid_info.bets !== null && this.bid_info.buynow == 0){
            this.bid_info.price = JSON.parse(this.bid_info.bets)[0].amount
          }
          else if(this.bid_info.bets == null && this.bid_info.buynow == 0){
            this.bid_info.price = this.bid_info.betStart
          }
        }

        else{
          this.bid_info.dateStart = new Date(this.bid_info.dateStart)
          this.dateStr = `${addZero(Number(this.bid_info.dateStart.getDate()))}.${addZero(Number(this.bid_info.dateStart.getMonth()+1))}.${this.bid_info.dateStart.getFullYear()}г`
          function addZero(num) {
            if (num <= 9) {
              num = '0' + num;
            }
            
            return num;
          }
        }
        if(this.bid_info.type !== 'Прошедшие'){
          setInterval(this.setTimer, 1000)
        }
        
      switch(this.bid_info.type){
            case 'Актуальные' || 'Купить сейчас':
                this.typeColor = '#cafcca'
                break;
            // case 'Запланированные':
            //     this.typeColor = '#fffa9e';
            //     break;
            case 'Прошедшие':
                this.typeColor = '#fbd9cf';
                break;
        }

        // this.bid_info.price = (this.bid_info.price.toLocaleString())

    })
  },

    setTimer(){
      let diff = 0
      if(this.bid_info.type == 'Актуальные' || this.bid_info.type == 'Прошедшие'){
          this.bid_info.dateEnd = new Date(this.bid_info.dateEnd)
          this.dateStr = `${addZero(Number(this.bid_info.dateEnd.getDate()))}.${addZero(Number(this.bid_info.dateEnd.getMonth()+1))}.${this.bid_info.dateEnd.getFullYear()}г`
          function addZero(num) {
            if (num <= 9) {
              num = '0' + num;
            }
            
            return num;
          }

          diff = Math.ceil((this.bid_info.dateEnd - Date.now())/1000)

          if(diff < 0){
          this.updateType('Прошедшие')
        }
          
        }
        else{
          this.bid_info.dateStart = new Date(this.bid_info.dateStart)
          this.dateStr = `${addZero(Number(this.bid_info.dateStart.getDate()))}.${addZero(Number(this.bid_info.dateStart.getMonth()+1))}.${this.bid_info.dateStart.getFullYear()}г`
          function addZero(num) {
            if (num <= 9) {
              num = '0' + num;
            }
            
            return num;
          }
          
          diff = Math.ceil((this.bid_info.dateStart - Date.now())/1000)

          if(diff < 0){
          this.updateType('Актуальные')
        }
        }
        
        

        let days = extract(diff, 60 * 60 * 24);
          let hours = extract(days.diff, 60 * 60);
          let minutes = extract(hours.diff, 60);
          let seconds = extract(minutes.diff, 1);
          this.timer = addZero(days.value) + 'д.&emsp;'
              + addZero(hours.value)
              + ':' + addZero(minutes.value)
              + ':' + addZero(seconds.value);

          // this.timer = this.timer.getDate()
          function extract(diff, formula) {
            var value = Math.floor(diff / formula);
            var diff = diff % formula;
            
            return {value: value, diff: diff};
          }

          function addZero(num) {
            if (num <= 9) {
              num = '0' + num;
            }
            
            return num;
          }
    },

    updateType(bid_type){
      axios.post('https://d2auction.ru/api.php', {
        action: 'update_bid',
        id: this.$props.id,
        type: bid_type
      }).then(window.location.reload())
    },

    addToFavorites(){
      if(this.$store.state.user_info.deposit == 1){
        this.$store.commit('addToFavourites', String(this.bid_info.id))
        axios.post('https://d2auction.ru/updateUser.php', {
          id: this.$store.state.user_info.id,
          favourites: JSON.stringify(this.$store.state.user_info.favourites)
        })
      }
      else{
        alert('Добавление в избранное недоступно без депозита')
      }
    },

    removeFromFavorites(){
      this.$store.commit('removeFromFavourites', String(this.bid_info.id))
      axios.post('https://d2auction.ru/updateUser.php', {
        id: this.$store.state.user_info.id,
        favourites: JSON.stringify(this.$store.state.user_info.favourites)
      })
    },

    makeBet(){
      if((Number(this.makeBetAmount) >= Number(this.bid_info.price)+Number(this.bid_info.minStep)) && ((Number(this.makeBetAmount) - Number(this.bid_info.price)) % Number(this.bid_info.minStep) == 0)){
        let bet_info = {
          user: this.$store.state.user_info.login,
          amount: Number(this.makeBetAmount)
        }
        let new_bet_info = [];
        if(this.bid_info.bets !== null){
          new_bet_info = JSON.parse(this.bid_info.bets)
        }
        new_bet_info.unshift(bet_info)
        axios.post('https://d2auction.ru/api.php', {
          action: 'new_bet',
          id: this.$props.id,
          bets: new_bet_info
        }).then(()=>window.location.reload())
      }
      else{
        alert('Некорректная сумма ставки, шаг ' + this.bid_info.minStep)
      }
    },

    sendTGMessage(message){
      const telegramBotToken = '6710893122:AAFpURFng1XAyisJB-gcv6hcCwCSjPdxcPU';
      const chatId = '@d2auction_chat';
      let data = {
          chat_id: chatId,
          text: message
      }
      axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data)
    },

    buyNow(){
      if(this.$store.state.user_info.deposit == 1){
        axios.post('https://d2auction.ru/sendRequest.php', {
          type: 'buy_now',
          name: this.$store.state.user_info.name,
          mail: this.$store.state.user_info.email,
          phone: this.$store.state.user_info.phone,
          bid: this.bid_info.title
        }).then(()=>{
          this.sendTGMessage('Заявка Buy Now\n' + this.$store.state.user_info.name + '\n' + this.$store.state.user_info.email + '\n' + this.$store.state.user_info.phone + '\n' + this.bid_info.title)
          alert('Заявка оформлена, мы свяжемся с вами в ближайшее время')
        })
      }
      else{
        alert('Необходимо внести депозит')
      }
    },

    updateBidInfo(){
      axios.post('https://d2auction.ru/api.php', {
      action: 'get_bid',
      id: this.$props.id
    }).then((response)=>{
      if(this.bid_info.type == 'Актуальные'){
          this.bid_info.bets = response.data.bets
          if(this.bid_info.bets !== null){
            this.bid_info.price = JSON.parse(this.bid_info.bets)[0].amount
          }
          else{
            this.bid_info.price = this.bid_info.betStart
          }
        }
    })
    },

    openImgGetSrc(index){
      document.querySelector('#itemSliderModalBid'+this.$props.id).querySelector('.active').classList.remove('active')
      document.querySelector('#itemSliderModalBid'+this.$props.id).querySelectorAll('.carousel-item')[index].classList.add('active')
      this.openImgSrc = index
      console.log(this.openImgSrc)
    }
  },

  beforeMount(){
    console.log(this.$props.id)

    this.loadBid()
    this.updateBidInfo()
    setInterval(this.updateBidInfo, 10000)
    

    axios.post('https://d2auction.ru/api.php', {
    action: 'get_bids',
    type: 'Актуальные',
    limit: 8
  }).then((response)=>{
    console.log(response)
    this.bids_actual = response.data
    this.bids_actual.forEach(bid => {
      if(bid.bets !== null){
            bid.price = JSON.parse(bid.bets)[0].amount
          }
          else{
            bid.price = bid.betStart
          }
    });
    
  })
    
    }
}
</script>