<template>
  <div class="properties section mt-5">
    <!-- <img :src="banner_desktop.url" v-if="banner_desktop.format=='image'" class="w-100 mb-5 d-none d-sm-block" style="object-fit: cover;" alt="">
      <img :src="banner_mob.url" v-if="banner_mob.format=='image'" class="w-100 mb-5 d-block d-sm-none" style="object-fit: cover;" alt="">

      <video :src="banner_desktop.url" v-if="banner_desktop.format=='video'" class="w-100 mb-5 d-none d-sm-block" style="object-fit: cover;" alt="" autoplay loop muted></video>
      <video :src="banner_mob.url" v-if="banner_mob.format=='video'" class="w-100 mb-5 d-block d-sm-none" style="object-fit: cover;" alt="" autoplay loop muted></video> -->
      <div class="mb-5 d-none d-sm-block">
        <div id="main_banner_desktop" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item" v-for="(item, index) in banner_desktop.url" :class="{'active': index==0}" data-bs-interval="10000">
              <img :src="item" class="d-block w-100">
            </div>
          </div>
          <!-- <button class="carousel-control-prev" type="button" data-bs-target="#main_banner_desktop" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#main_banner_desktop" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button> -->
        </div>
      </div>
      
      <div class="mb-5 d-block d-sm-none">
        <div id="main_banner_mob" class="carousel slide " data-bs-ride="carousel" >
          <div class="carousel-inner">
            <div class="carousel-item" v-for="(item, index) in banner_mob.url" :class="{'active': index==0}" data-bs-interval="10000">
              <img :src="item" class="d-block w-100">
            </div>
          </div>
          <!-- <button class="carousel-control-prev" type="button" data-bs-target="#main_banner_mob" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#main_banner_mob" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button> -->
        </div>
      </div>
      
    <div class="container">

      <div class="row">
        <div class="col-lg-4 offset-lg-4">
          <div class="section-heading text-center">
            
            <h6>Мотоциклы</h6>
            <h2 class="w-100">Аукционы</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <bid-preview v-for="bid in bids_actual"
        :type = bid.type
        :title = bid.title
        :price = bid.price
        :description = '[JSON.parse(bid.params)["Объем"], JSON.parse(bid.params)["Год"], JSON.parse(bid.params)["Пробег"], JSON.parse(bid.params)["Город"]]'
        :images = JSON.parse(bid.images)
        :id = bid.id 
        :dateEnd = bid.dateEnd 
        :dateStart = bid.dateStart
        :currency = bid.currency />

        <div class="w-100 d-flex mb-5" v-if="bids_actual.length > 0">
          <button class="more-button mx-auto" onclick="window.location.href='/bids?actual'">Смотреть все</button>
        </div>

        <!-- <bid-preview v-for="bid in bids_soon"
        :type = bid.type
        :title = bid.title
        :price = bid.price
        :description = '[JSON.parse(bid.params)["Объем"], JSON.parse(bid.params)["Год"], JSON.parse(bid.params)["Пробег"], JSON.parse(bid.params)["Город"]]'
        :images = JSON.parse(bid.images)
        :id = bid.id 
        :dateEnd = bid.dateEnd 
        :dateStart = bid.dateStart
        :currency = bid.currency /> -->

        <!-- <div class="w-100 d-flex mb-5" v-if="bids_soon.length > 0">
          <button class="more-button mx-auto" onclick="window.location.href='/bids?buy_now'">Смотреть все</button>
        </div> -->

        <bid-preview v-for="bid in bids_history"
        :type = bid.type
        :title = bid.title
        :price = bid.price
        :description = '[JSON.parse(bid.params)["Объем"], JSON.parse(bid.params)["Год"], JSON.parse(bid.params)["Пробег"], JSON.parse(bid.params)["Город"]]'
        :images = JSON.parse(bid.images)
        :id = bid.id
        :dateEnd = bid.dateEnd 
        :dateStart = bid.dateStart
        :currency = bid.currency
        :sold = bid.sold />

        <div class="w-100 d-flex mb-5" v-if="bids_history.length > 0">
          <button class="more-button mx-auto" onclick="window.location.href='/bids?history'">Смотреть все</button>
        </div>
      </div>

    </div>
  </div>



  <div class="featured section mt-5" id="about_us"> 
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="left-image w-100">
            <img src="../assets/image_about.jpg" class="ps-0" alt="">
          </div>
        </div>
        <div class="col-lg-5">
          <div class="section-heading">
            <h6>О нас</h6>
            <h2 class="w-100">Мото аукционы России</h2>
          </div>
          
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Как работает аукцион ?
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne">
                <div class="accordion-body" v-html="about_us.auction" style="white-space: pre-wrap;">
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Как сделать ставку ?
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo">
                <div class="accordion-body" v-html="about_us.bet" style="white-space: pre-wrap;">
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                 Оплата и доставка
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree">
                <div class="accordion-body" v-html="about_us.delivery" style="white-space: pre-wrap;">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="info-table">
            <ul>
              <li>
                <i class="bi bi-cash-coin" style="font-size: 32px; color: #5b686d !important; line-height: 1;"></i>
                <h4>{{ bids_count }} <br><span>Прошедших аукционов</span></h4>
              </li>
              <li>
                <i class="bi bi-check2-square" style="font-size: 32px; color: #5b686d !important; line-height: 1;"></i>
                <h4>Прозрачность<br><span>Гарантия сделки</span></h4>
              </li>
              <li>
                <i class="bi bi-truck" style="font-size: 32px; color: #5b686d !important; line-height: 1;"></i>
                <h4>Доставка<br><span>Организация логистики</span></h4>
              </li>
              <li>
                <i class="bi bi-shield-lock-fill" style="font-size: 32px; color: #5b686d !important; line-height: 1;"></i>
                <h4>Безопасность<br><span>Поддержка 24/7</span></h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="contact section">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 offset-lg-4">
          <div class="section-heading text-center">
            <h6 style="color: #fff;">Контакты</h6>
            <h2>Поддержка 24/7</h2>
          </div>
        </div>
      </div>
    </div>

  <div class="contact-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class="row">
            <div class="col-lg-6">
              <div class="item phone">
                <h6 class="text-center">+7 (985) 220 63 99 <br></h6>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="item email">
                <h6 class="text-center">d2@d2auction.ru<br></h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div id="contact-form">
            <div class="row">
              <div class="col-lg-12">
                <fieldset>
                  <input type="name" placeholder="Ваше ФИО..." v-model="userHelpName">
                </fieldset>
              </div>
              <div class="col-lg-12">
                <fieldset>
                  <input type="text" placeholder="Ваш E-mail..." v-model="userHelpMail">
                </fieldset>
              </div>
              <div class="col-lg-12">
                <fieldset>
                  <input type="text" placeholder="Ваш номер телефона..." v-model="userHelpPhone">
                </fieldset>
              </div>
              <div class="col-lg-12">
                <fieldset>
                  <button class="orange-button" @click="sendHelp">Отправить заявку</button>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  </div>

</template>

<script>
import BidPreview from '../components/BidPreview.vue'
import axios from 'axios';

export default{
  components:{
    BidPreview
  },
  data(){
    return{
      bids_actual: [],
      // bids_soon: [],
      bids_history: [],
      userHelpName: '',
      userHelpMail: '',
      userHelpPhone: '',
      about_us: {},
      bids_count: '',
      banner_desktop: [],
      banner_mob: []
    }
  },

  methods: {
    sendTGMessage(message){
    const telegramBotToken = '6710893122:AAFpURFng1XAyisJB-gcv6hcCwCSjPdxcPU';
    const chatId = '@d2auction_chat';
    let data = {
        chat_id: chatId,
        text: message
    }
    axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data)
  },

    sendHelp(){
      if(this.userHelpName!=='' && this.userHelpMail!==''){
      axios.post('https://d2auction.ru/sendRequest.php', {
        type: 'help',
        name: this.userHelpName,
        mail: this.userHelpMail,
        phone: this.userHelpPhone
      }).then(()=>{
        this.sendTGMessage('Заявка Помощь\n' + this.userHelpName + '\n' + this.userHelpMail + '\n' + this.userHelpPhone)
        alert('Заявка оформлена, мы свяжемся с вами в ближайшее время')
        window.location.reload()
      })
      }
      else{
        alert('Заполните поля корректно')
      }
    },

    async loadData(){
      await axios.post('https://d2auction.ru/api.php', {
    action: 'get_bids',
    type: 'Актуальные',
    limit: 3
  }).then((response)=>{
    console.log(response)
    this.bids_actual = response.data
    this.bids_actual.forEach(bid => {
      if(bid.bets !== null){
            bid.price = JSON.parse(bid.bets)[0].amount
          }
          else{
            bid.price = bid.betStart
          }
    });
    
  })

  // if(this.$store.state.user_info.deposit == 1){
  //   await axios.post('https://d2auction.ru/api.php', {
  //     action: 'get_bids',
  //     type: 'Запланированные',
  //     limit: 3
  //   }).then((response)=>{
  //     console.log(response)
  //     this.bids_soon = response.data
      
  //   })
  // }

  

  await axios.post('https://d2auction.ru/api.php', {
    action: 'get_bids',
    type: 'Прошедшие',
    limit: 3
  }).then((response)=>{
    console.log(response)
    this.bids_history = response.data
    this.bids_history.forEach(bid => {
      if(bid.bets !== null  && bid.buynow == 0){
        bid.price = JSON.parse(bid.bets)[0].amount
      }
      else if (bid.bets == null && bid.buynow == 0){
        bid.price = bid.betStart
      }
    });
  })

  axios.post('https://d2auction.ru/about_us.php').then((response)=>{
    this.about_us = response.data
  })

  axios.post('https://d2auction.ru/api.php', {action: 'get_bids_count'}).then((response)=>{
    this.bids_count = response.data
  })
    },

    getBanner(){
      axios.post('https://d2auction.ru/get_banner.php').then((response)=>{
        this.banner_desktop = response.data['desktop']
        this.banner_mob = response.data['mob']

        this.banner_desktop.url = JSON.parse(this.banner_desktop.url)
        this.banner_mob.url = JSON.parse(this.banner_mob.url)

        console.log(this.banner_desktop)
        console.log(this.banner_mob)
      })
    }
  },

  beforeMount(){
   
    this.loadData()
    this.getBanner()
  },
  mounted(){
      let getParam = window.location.hash.replace('#', '')
      console.log(getParam)

      if(getParam == 'about_us'){
        setTimeout(()=>{document.getElementById("about_us").scrollIntoView({block:"nearest", behavior:"smooth"})}, 1500)
        
      }
  }

}
</script>