<template>
    <!-- <admin-enter v-if="$store.state.admin == false" /> -->
    <admin-panel v-if="$store.state.user_info.login == 'admin'"/>
</template>

<script>
import AdminEnter from '../components/AdminEnter.vue'
import AdminPanel from '@/components/AdminPanel.vue';
export default{
    components: {
        AdminEnter, AdminPanel
    }
}
</script>