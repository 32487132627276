<template>
      <!--Modal sign in -->
  <div class="modal fade" id="signInModal" tabindex="-1" aria-labelledby="signInModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="signInModalLabel">Авторизация</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body d-flex">
          <form class="signin_form w-75 mx-auto my-2">
            <input type="text" class="form-control mt-1" v-model="loginAuth" placeholder="Введите логин или E-mail">
            <input type="password" class="form-control mt-3" v-model="passwordAuth" placeholder="Введите пароль">
            <div class="main-button d-flex flex-column">
              <a class="btn mt-3 mx-auto" @click="auth">Войти в кабинет</a>
              <span class="mx-auto mt-3" style="text-decoration: underline; font-size: 0.8em; cursor: pointer;"  data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#signUpModal">Зарегистрироваться</span>
              <span class="mx-auto mt-2" style="text-decoration: underline; font-size: 0.8em; cursor: pointer;" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#forgotPasswordModal">Забыли пароль?</span>
            </div>
            
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--End Modal-->

  <div class="modal fade" id="forgotPasswordModal" tabindex="-1" aria-labelledby="forgotPasswordModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="forgotPasswordModalLabel">Восстановление пароля</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body d-flex">
          <form class="signin_form w-75 mx-auto my-2">
            <input type="text" class="form-control mt-1" v-model="mailForgot" placeholder="Введите E-mail">
            <span class="mt-1">Мы отправим вам на почту временный пароль. Вы сможете сменить его в личном кабинете</span>
            <div class="main-button d-flex flex-column">
              <a class="btn mt-3 mx-auto" @click="forgotPassword">Отправить</a>
              <span class="mx-auto mt-3" style="text-decoration: underline; font-size: 0.8em; cursor: pointer;" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#signInModal">Авторизация</span>
            </div>
            
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="signUpModal" tabindex="-1" aria-labelledby="signUpModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="signUpModalLabel">Регистрация</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body d-flex">
          <form class="signup_form w-75 mx-auto my-2">
            <input type="text" class="form-control mt-3" v-model="nameReg" placeholder="Введите ФИО">
            <input type="text" class="form-control mt-3" v-model="phoneReg" placeholder="Введите номер телефона">
            <input type="text" class="form-control mt-3" v-model="emailReg" placeholder="Введите E-mail">
            <input type="text" class="form-control mt-3" v-model="loginReg" placeholder="Придумайте логин">
            <input type="password" class="form-control mt-3" v-model="passwordReg" placeholder="Придумайте пароль">
            <div class="main-button d-flex flex-column">
              <a class="btn mt-3 mx-auto" @click="reg">Зарегистрироваться</a>
              <span class="mx-auto mt-3" style="text-decoration: underline; font-size: 0.8em; cursor: pointer;" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#signInModal">Авторизация</span>
            </div>
            
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default{
  data(){
    return{
      loginAuth: '',
      passwordAuth: '',
      nameReg: '',
      phoneReg: '',
      emailReg: '',
      loginReg: '',
      passwordReg: '',
      mailForgot: ''
    }
  },

  methods: {
    reg(){
      if(this.nameReg.length>0 && this.phoneReg.length>0 && this.emailReg.length>0 && this.loginReg.length>0 && this.passwordReg.length>0){

        let regData = {
          name: this.nameReg,
          phone: this.phoneReg,
          email: this.emailReg,
          login: this.loginReg,
          password: this.passwordReg
        }

        axios.post('https://d2auction.ru/reg.php', regData).then((response)=>{
          console.log(response)

          if(response.data['id'] !== undefined){
            this.$store.commit('authUser', response.data)
            window.location.href = '/lk'
          }
          else{
            alert(response.data)
          }
        })
      }
      else{
        alert('Заполните все поля корректно')
      }
    },

    auth(){
      if(this.loginAuth.length>0 && this.passwordAuth.length>0){
        let authData = {
          login: this.loginAuth,
          password: this.passwordAuth
        }
        axios.post('https://d2auction.ru/auth.php', authData).then((response)=>{
          console.log(response)

          if(response.data !== 'Логин/почта или пароль не совпадают'){
            this.$store.commit('authUser', response.data)
            window.location.href = '/lk'
          }
          else{
            alert(response.data)
          }
        })
      }
      else{
        alert('Заполните поля корректно')
      }
    },

    forgotPassword(){
      axios.post('https://d2auction.ru/send_code.php', {mail: this.mailForgot}).then((response)=>{
          if(response.data == 'успешно'){
            alert('Временный пароль отправлен на указанный e-mail')
            this.mailForgot = ''
          }
          else{
            alert(response.data)
          }
      })
    }
  }
}
</script>