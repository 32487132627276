<template>
    <div class="container">
        <div class="admin-form my-5">
            <h3 class="mb-5 text-center">Создать лот</h3>

            <select class="form-select" v-model="type">
                <option disabled value="">Тип</option>
                <option>Прошедшие</option>
                <option>Актуальные</option>
                <!-- <option>Запланированные</option> -->
            </select>

            <label>Название</label>
            <input type="text" class="form-control" v-model="title" placeholder="Honda RC51">

            <label>Цена</label>
            <input type="text" class="form-control" v-model="price" placeholder="300000">

            <label>Описание</label>
            <textarea class="form-control" rows="10" v-model="description" placeholder="Введите описание лота"></textarea>

            <label>Фото</label>
            <input type="file" id="images" ref="images" class="form-control" @change="handleFilesUpload()" multiple="multiple">

            <label>PDF файл</label>
            <input type="file" id="pdf_file" ref="pdf_file" class="form-control" @change="handlePDFFileUpload()">

            <h5 class="mt-5">Характеристики</h5>

            <label>Год</label>
            <input type="text" class="form-control" v-model="year" placeholder="2003">

            <label>Марка</label>
            <input type="text" class="form-control" v-model="brand" placeholder="Honda">

            <label>Модель</label>
            <input type="text" class="form-control" v-model="model" placeholder="RC51">

            <label>Объем (куб. см)</label>
            <input type="text" class="form-control" v-model="power" placeholder="98">

            <label>Пробег (км)</label>
            <input type="text" class="form-control" v-model="mileage" placeholder="85000">

            <label>Город</label>
            <input type="text" class="form-control" v-model="location" placeholder="Нью Йорк">

            <label>Доп. информация</label>
            <textarea type="text" class="form-control" rows="5" v-model="extraInfo" placeholder="Введите дополнительную информацию"></textarea>

            <label>Дата начала</label>
            <vue-date-picker v-model="dateStart" />

            <label>Дата окончания</label>
            <vue-date-picker v-model="dateEnd" />

            <label>Минимальный шаг</label>
            <input type="text" class="form-control" v-model="minStep" placeholder="1000">

            <label>Стартовая сумма</label>
            <input type="text" class="form-control" v-model="betStart" placeholder="100000">

            <label>Валюта</label>
            <select class="form-select" v-model="currency">
                <option disabled value="">Валюта</option>
                <option>рубль</option>
                <option>доллар</option>
            </select>

            <button type="button" class="btn btn-success mt-5" @click="addBid">Создать лот</button>
        </div>

        <div class="admin-form-extra mb-5">
            <h3 class="mb-5 text-center">Дополнительно</h3>

            <select class="form-select mb-3" v-model="adminExtra_title">
            <option value="" disabled selected>Название лота</option>
            <option v-for="title in allBidsTitle">{{ title }}</option>
            </select>

            <select class="form-select" v-model="adminExtra_action">
                <option disabled value="" selected>Действие</option>
                <option value="hideLot">Скрыть лот</option>
                <option value="displayLot">Открыть лот</option>
                <option value="switchOnBuyNow">Включить buy now</option>
                <option value="switchOffBuyNow">Отключить buy now</option>
                <!-- <option value="moveToSoon">Перенести в запланированные</option> -->
                <option value="moveToActual">Перенести в актуальные</option>
                <option value="moveToHistory">Перенести в прошедшие</option>
                <option value="deleteBid">Удалить лот</option>
                <option value="updateImages">Обновить фото</option>
                <option value="switchOnSold">Добавить в продано</option>
                <option value="switchOffSold">Убрать из продано</option>
            </select>

            <div class="mt-3" v-if="adminExtra_action == 'moveToSoon' || adminExtra_action == 'moveToActual' || adminExtra_action == 'moveToHistory'">
                <label>Дата начала</label>
                <vue-date-picker v-model="newDateStart" />
            </div>
            
            <div class="mt-2" v-if="adminExtra_action == 'moveToSoon' || adminExtra_action == 'moveToActual' || adminExtra_action == 'moveToHistory'">
                <label>Дата окончания</label>
                <vue-date-picker v-model="newDateEnd" />
            </div>

            <label v-if="adminExtra_action == 'updateImages'">Фото</label>
            <input type="file" id="imagesUpdate" ref="imagesUpdate" class="form-control" @change="handleFilesUploadUpdate()" v-if="adminExtra_action == 'updateImages'" multiple="multiple">

            <button type="button" class="btn btn-success mt-3" @click="adminExtra_edit">Применить</button>

            <div class="admin-form-extra mb-5">
                <h3 class="mb-5 text-center">Дополнительно</h3>

                <label>Загрузите баннер</label>
                <input type="file" id="banner" ref="banner" class="form-control" @change="handleBannerFileUpload()"  multiple="multiple">

                <select class="form-select mt-3" v-model="banner_type">
                    <option disabled value="" selected>Выберите тип</option>
                    <option value="desktop">Десктоп</option>
                    <option value="mob">Мобильный</option>
                </select>

                <!-- <select class="form-select mt-3" v-model="banner_format">
                    <option disabled value="" selected>Выберите формат</option>
                    <option value="image">Изображение</option>
                    <option value="video">Видео</option>
                </select> -->

                <button type="button" class="btn btn-success mt-3" @click="upload_banner">Загрузить</button>
            </div>
        </div>
    </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import axios from 'axios';

export default{
    components: {
        VueDatePicker
    },

    data(){
        return{
            type: '',
            title: '',
            price: '',
            description: '',
            images: '',
            year: '',
            brand: '',
            model: '',
            power: '',
            mileage: '',
            location: '',
            extraInfo: '',
            dateStart: null,
            dateEnd: null,
            newDateStart: null,
            newDateEnd: null,
            minStep: '',
            images_folder: '',
            betStart: '',
            pdf_file: '',
            adminExtra_title: '',
            adminExtra_action: '',
            allBidsTitle: [],
            currency: '',
            banner: '',
            banner_type: '',
            banner_format: ''
        }
    },

    methods: {
        handleFilesUpload(){
            this.images = this.$refs.images.files;
        },

        handleFilesUploadUpdate(){
            this.images = this.$refs.imagesUpdate.files;
        },

        handlePDFFileUpload(){
            this.pdf_file = this.$refs.pdf_file.files[0];
        },

        handleBannerFileUpload(){
            this.banner = this.$refs.banner.files;
        },

        addBid(){

            this.images_folder = this.model.replaceAll(' ', '')

            let formData = new FormData()

            for( let i = 0; i < this.images.length; i++ ){
                let image = this.images[i]
                formData.append('images[' + i + ']', image)
            }
            this.images_folder.replace(' ', '')
            formData.append('folder', this.images_folder)

            axios.post( 'https://d2auction.ru/upload_images.php',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then((response)=>{
                if(response.data == 'Файлы загружены'){
                    if(this.pdf_file !== ''){
                    let pdfFile = new FormData()

                    pdfFile.append('pdf_file', this.pdf_file)
                    pdfFile.append('folder', this.images_folder)

                    axios.post('https://d2auction.ru/upload_pdf.php',
                        pdfFile,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then((response)=>console.log(response))
                    }

                    let images_array = []
                    for( let i = 0; i < this.images.length; i++ ){
                        images_array.push(`${this.images_folder}/${this.images[i].name}`)
                    }

                    let bid_data = {
                        action: 'add_bid',
                        type: this.type,
                        title: this.title,
                        price: this.price,
                        description: this.description,
                        params: JSON.stringify({
                            "Год": `${this.year} г.`,
                            "Марка": this.brand,
                            "Модель": this.model,
                            "Объем": `${this.power} куб. см`,
                            "Пробег": `${this.mileage} км`,
                            "Город": this.location
                        }),
                        extrainfo: this.extraInfo,
                        dateStart: `${this.dateStart.getFullYear()}-${this.dateStart.getMonth()+1}-${this.dateStart.getDate()} ${this.dateStart.getHours()}:${this.dateStart.getMinutes()}:${this.dateStart.getSeconds()}`,
                        dateEnd: `${this.dateEnd.getFullYear()}-${this.dateEnd.getMonth()+1}-${this.dateEnd.getDate()} ${this.dateEnd.getHours()}:${this.dateEnd.getMinutes()}:${this.dateEnd.getSeconds()}`,
                        minStep: this.minStep,
                        images: JSON.stringify(images_array),
                        betStart: this.betStart,
                        currency: this.currency 
                    }

                    if(this.pdf_file !== ''){
                        bid_data.pdf_file = `${this.images_folder}/${this.pdf_file.name}`
                    }
                    else{
                        bid_data.pdf_file = ''
                    }

                    axios.post('https://d2auction.ru/api.php', bid_data).then(()=>{
                        alert('Лот создан!')
                        window.location.reload()
                    })
                }
                else{
                    alert('Ошибка при загрузке файлов')
                }
            })
        },

        adminExtra_edit(){
            if((this.adminExtra_action == 'moveToSoon' || this.adminExtra_action == 'moveToActual' || this.adminExtra_action == 'moveToHistory') && (this.newDateStart == null || this.newDateEnd == null)){
                alert("Введите дату")
            }
            
            else{
                let editData = {
                    action: this.adminExtra_action,
                    title: this.adminExtra_title
                }

                if(this.adminExtra_action == 'moveToSoon' || this.adminExtra_action == 'moveToActual' || this.adminExtra_action == 'moveToHistory'){
                    editData.newDateStart = `${this.newDateStart.getFullYear()}-${this.newDateStart.getMonth()+1}-${this.newDateStart.getDate()} ${this.newDateStart.getHours()}:${this.newDateStart.getMinutes()}:${this.newDateStart.getSeconds()}`,
                    editData.newDateEnd = `${this.newDateEnd.getFullYear()}-${this.newDateEnd.getMonth()+1}-${this.newDateEnd.getDate()} ${this.newDateEnd.getHours()}:${this.newDateEnd.getMinutes()}:${this.newDateEnd.getSeconds()}`
                }

                if(this.adminExtra_action == 'updateImages'){
                    axios.post('https://d2auction.ru/api.php', {action: 'removeFolder', title: this.adminExtra_title})
                    .then((response)=>{
                        console.log(response)
                        if(response.status == 200){
                            this.images_folder = response.data
                            console.log(this.images_folder)

                            let formData = new FormData()

                            for( let i = 0; i < this.images.length; i++ ){
                                let image = this.images[i]
                                formData.append('images[' + i + ']', image)
                            }

                            formData.append('folder', this.images_folder)

                            axios.post( 'https://d2auction.ru/upload_images.php',
                                formData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }
                            ).then((response)=>{
                                console.log(response)
                                if(response.data == 'Файлы загружены'){
                                    let images_array = []
                                    for( let i = 0; i < this.images.length; i++ ){
                                        images_array.push(`${this.images_folder}/${this.images[i].name}`)
                                    }
                                    axios.post('https://d2auction.ru/api.php', {action: 'updateImagesSql', title: this.adminExtra_title, images: JSON.stringify(images_array)})
                                    .then((response)=>{
                                        console.log(response)
                                        if(response.status == 200){
                                            alert('Успешно')
                                            window.location.reload()
                                        }
                                        else{
                                            alert('Ошибка')
                                        }
                                    })
                                }
                            })
                        }
                        else{
                            alert('Ошибка в запросе')
                        }
                    })
                }
                else{
                    axios.post('https://d2auction.ru/api.php', editData)
                    .then((response)=>{
                        console.log(response)
                        if(response.status == 200){
                            alert('Успешно')
                            window.location.reload()
                            
                        }
                        else{
                            alert('Ошибка в запросе')
                        }
                    })
                }

                
            }
        },

        upload_banner(){
            if(this.banner !== '' && this.banner_type !== ''){
                let banner_file = new FormData()
                for( let i = 0; i < this.banner.length; i++ ){
                    let banners = this.banner[i]
                    banner_file.append('banner[' + i + ']', banners)
                }
                // banner_file.append('banner', this.banner)
                banner_file.append('type', this.banner_type)
                // banner_file.append('format', this.banner_format)

                axios.post('https://d2auction.ru/upload_banner.php',
                    banner_file,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((response)=>{
                        if(response.data == 'Файл загружен'){
                            alert('Успешно')
                            window.location.reload()
                        }
                    })
                }
                else{
                    alert('Загрузите файл и выберите тип баннера')
                }
        }
    },

    beforeMount(){
        axios.post('https://d2auction.ru/api.php', {action: 'get_all_bids_title'})
        .then((response)=>{
            this.allBidsTitle = response.data
        })
    }
}
</script>

<style lang="scss">
.admin-form, .admin-form-extra{
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    label{
        margin-top: 2em;
        margin-bottom: 0.5em;
    }

    input[type="text"], select{
        width: 30%;
        min-width: 200px;
    }
}

.admin-form-extra select{
    width: 50%;
    min-width: 300px;
}
</style>