<template>
  <!-- <div class="modal fade px-0" :id="'openImg'+id" tabindex="-1" aria-labelledby="openImgLabel" aria-hidden="true">
    <div class="modal-dialog modal-fullscreen modal-dialog-centered" style="height: fit-content;">
      <div class="modal-content" style="max-height: 100vh;">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>

        <div :id="'itemSliderModal'+id" class="carousel carousel-dark slide item-slider" data-bs-touch="true" data-bs-interval="false">

        <div class="carousel-inner">
          <div class="carousel-item" :class="{'active': index === openImgSrc}" v-for="(img, index) in images" >
            <img :src="'https://d2auction.ru/'+img" class="d-block w-100" style="aspect-ratio: 16 / 9; object-fit: contain; max-height: 90vh;">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" :data-bs-target="'#itemSliderModal'+id"  data-bs-slide="prev" >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Предыдущий</span>
        </button>
        <button class="carousel-control-next" type="button" :data-bs-target="'#itemSliderModal'+id" data-bs-slide="next" >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Следующий</span>
        </button>
        </div>

      </div>
    </div>
  </div> -->

    <div class="col-md-4">
          <div class="item">
            <div class="item-img" style="position: relative;">
              <div :id="'itemSliderPreview'+id" class="carousel slide item-slider" data-bs-touch="true" data-bs-interval="false">

                <div class="carousel-inner" style="border-radius: 10px;">
                  <div class="carousel-item" :class="{'active': index === 0}" style="border-radius: 10px;" v-for="(img, index) in images" >
                    <a :href="'/bids/'+id"><img :src="img" class="d-block w-100" style="aspect-ratio: 16 / 9; object-fit: cover;"></a>
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" :data-bs-target="'#itemSliderPreview'+id" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Предыдущий</span>
                </button>
                <button class="carousel-control-next" type="button" :data-bs-target="'#itemSliderPreview'+id" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Следующий</span>
                </button>
              </div>

              <div class="sold-image" style="width: 30%; position: absolute; top: 10px; right: 20px;" v-if="$props.sold == '1'">
                <img src="../assets//sold.png" alt="" class="w-100">
              </div>

              </div>
            
            <div class="item-content">
            <a :href="'/bids/'+id">
              <div class="d-flex justify-content-end" v-if="type !== 'Запланированные'">
                <p v-if="type == 'Прошедшие'" class="my-sm-3 my-1" style="font-size: 0.9em;"><i class="bi bi-clock-history"></i> {{ dateStr }}</p>
                <p v-else class="my-sm-3 my-1" style="font-size: 0.9em;"><i class="bi bi-clock-history"></i> <span v-html="timer"></span></p>
              </div>
              <div class="mt-3" v-else></div>
              
            <div class="d-flex justify-content-between mb-sm-3 mb-0">
              <span class="category" :style="{backgroundColor: typeColor}">{{ type }}</span>
              <h6 class="me-1" v-if="currency == 'рубль' && type !== 'Запланированные'">₽{{ priceFormat }}</h6>
              <h6 class="me-1" v-if="currency == 'доллар' && type !== 'Запланированные'">${{ priceFormat }}</h6>
            </div>
            <h4>{{ title }}</h4>
          </a>
              <ul>
                <li><span style="font-weight: 400;" v-for="item in description"> {{ item }} &nbsp; </span></li>
                <li class="my-2 d-flex justify-content-between"><div class="my-auto" v-if="type !== 'Запланированные'"><i class="bi bi-eye"></i> Сейчас смотрят: {{ watchNow }} чел.</div>
                  <div v-if="$store.state.user == true">
                    <a v-if="$store.state.user_info.favourites.includes(id)" @click="removeFromFavorites" style="cursor: pointer; font-size: 1.2em;"><i class="bi bi-heart-fill" style="color: #DC143C"></i></a>
                    <a v-else @click="addToFavorites" style="cursor: pointer; font-size: 1.2em;"><i class="bi bi-heart" style="color: #DC143C"></i></a>
                  </div></li> 
              </ul>            

          
            </div>
          </div>
        </div>
</template>

<script>
import axios from 'axios'
export default{
    props: {
        type: String,
        title: String,
        price: Number,
        description: Array,
        images: Array,
        link: String,
        id: Number,
        dateEnd: String,
        dateStart: String,
        currency: String,
        sold: String
    },

    data(){
        return{
            typeColor: '',
            dateEnd: this.$props.dateEnd,
            dateStart: this.$props.dateStart,
            timer: '',
            dateStr: '',
            watchNow: '',
            priceFormat: Number(this.$props.price).toLocaleString(),
            openImgSrc: 0,

        }
    },

    methods: {
      randomInt(min=3, max=30){
        let rand = min + Math.random() * (max - min);
        this.watchNow = Math.round(rand);

        let randZero = 1 + Math.random() * 2
        if(Math.round(randZero) == 1){
          this.watchNow = 0
        }
      },

      setTimer(){
      let diff = 0
      if(this.$props.type == 'Актуальные' || this.$props.type == 'Прошедшие'){
          this.dateEnd = new Date(this.dateEnd)
          this.dateStr = `${addZero(Number(this.dateEnd.getDate()))}.${addZero(Number(this.dateEnd.getMonth()+1))}.${this.dateEnd.getFullYear()}г`

          diff = Math.ceil((this.dateEnd - Date.now())/1000)

          if(diff < 0){
          this.updateType('Прошедшие')
        }
          
        }
        else{
          this.dateStart = new Date(this.dateStart)
          this.dateStr = `${addZero(Number(this.dateStart.getDate()))}.${addZero(Number(this.dateStart.getMonth()+1))}.${this.dateStart.getFullYear()}г`
          diff = Math.ceil((this.dateStart - Date.now())/1000)

          if(diff < 0){
          this.updateType('Актуальные')
        }
        }

        let days = extract(diff, 60 * 60 * 24);
          let hours = extract(days.diff, 60 * 60);
          let minutes = extract(hours.diff, 60);
          let seconds = extract(minutes.diff, 1);
          this.timer = addZero(days.value) + 'д.&emsp;'
              + addZero(hours.value)
              + ':' + addZero(minutes.value)
              + ':' + addZero(seconds.value);

          // this.timer = this.timer.getDate()
          function extract(diff, formula) {
            var value = Math.floor(diff / formula);
            var diff = diff % formula;
            
            return {value: value, diff: diff};
          }

          function addZero(num) {
            if (num <= 9) {
              num = '0' + num;
            }
            
            return num;
          }
    },

    updateType(bid_type){
      axios.post('https://d2auction.ru/api.php', {
        action: 'update_bid',
        id: this.$props.id,
        type: bid_type
      }).then(window.location.reload())
    },

    addToFavorites(){
      if(this.$store.state.user_info.deposit == 1){
        this.$store.commit('addToFavourites', String(this.id))
        axios.post('https://d2auction.ru/updateUser.php', {
          id: this.$store.state.user_info.id,
          favourites: JSON.stringify(this.$store.state.user_info.favourites)
        })
      }
      else{
        alert('Добавление в избранное недоступно без депозита')
      }
    },

    removeFromFavorites(){
      this.$store.commit('removeFromFavourites', String(this.id))
      axios.post('https://d2auction.ru/updateUser.php', {
        id: this.$store.state.user_info.id,
        favourites: JSON.stringify(this.$store.state.user_info.favourites)
      })
    },

    openImgGetSrc(index){
      document.querySelector('#itemSliderModal'+this.$props.id).querySelector('.active').classList.remove('active')
      document.querySelector('#itemSliderModal'+this.$props.id).querySelectorAll('.carousel-item')[index].classList.add('active')
      this.openImgSrc = index
      console.log(this.openImgSrc)
    }
    },

    beforeMount(){
      this.randomInt()
      console.log(this.dateEnd)
      if(this.$props.type !== 'Прошедшие'){
          setInterval(this.setTimer, 1000)
        }
        else{
          this.dateEnd = new Date(this.dateEnd)
          this.dateStr = `${addZero(Number(this.dateEnd.getDate()))}.${addZero(Number(this.dateEnd.getMonth()+1))}.${this.dateEnd.getFullYear()}г`
          function addZero(num) {
            if (num <= 9) {
              num = '0' + num;
            }
            
            return num;
          }
        }

        switch(this.type){
            case 'Актуальные' || 'Купить сейчас':
                this.typeColor = '#cafcca'
                break;
            // case 'Запланированные':
            //     this.typeColor = '#fffa9e';
            //     break;
            case 'Прошедшие':
                this.typeColor = '#fbd9cf';
                break;
            
        }
    }
}
</script>