<template>
    <div class="page-heading header-text">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!-- <span class="breadcrumb"><a href="/">Главная</a> / <a href="/bids">Аукционы</a></span> -->
          <h3 class="my-5" style="font-size: 30px; color: #000; text-align: center;">Аукционы</h3>
        </div>
      </div>
    </div>
  </div>

  <div class="section properties mt-0" style="min-height: 70vh">
    <div class="container">
      <ul class="properties-filter">
        <li>
          <a @click="filter_active = 1" :class="{'filter_active': filter_active === 1}">Актуальные</a>
        </li>
        <!-- <li v-if="this.$store.state.user_info.deposit == 1">
          <a @click="filter_active = 2" :class="{'filter_active': filter_active === 2}">Запланированные</a>
        </li> -->
        <li>
          <a @click="filter_active = 3" :class="{'filter_active': filter_active === 3}">Прошедшие</a>
        </li>
      </ul>
      <div class="row properties-box">

        <bid-preview v-for="bid in bids_actual" v-if="filter_active === 1"
        :type = bid.type
        :title = bid.title
        :price = bid.price
        :description = '[JSON.parse(bid.params)["Объем"], JSON.parse(bid.params)["Год"], JSON.parse(bid.params)["Пробег"], JSON.parse(bid.params)["Город"]]'
        :images = JSON.parse(bid.images)
        :id = bid.id 
        :dateEnd = bid.dateEnd 
        :dateStart = bid.dateStart
        :currency = bid.currency />

        <!-- <bid-preview v-for="bid in bids_soon" v-if="filter_active === 2"
        :type = bid.type
        :title = bid.title
        :price = bid.price
        :description = '[JSON.parse(bid.params)["Объем"], JSON.parse(bid.params)["Год"], JSON.parse(bid.params)["Пробег"], JSON.parse(bid.params)["Город"]]'
        :images = JSON.parse(bid.images)
        :id = bid.id 
        :dateEnd = bid.dateEnd 
        :dateStart = bid.dateStart
        :currency = bid.currency /> -->

        <bid-preview v-for="bid in bids_history" v-if="filter_active === 3"
        :type = bid.type
        :title = bid.title
        :price = bid.price
        :description = '[JSON.parse(bid.params)["Объем"], JSON.parse(bid.params)["Год"], JSON.parse(bid.params)["Пробег"], JSON.parse(bid.params)["Город"]]'
        :images = JSON.parse(bid.images)
        :id = bid.id
        :dateEnd = bid.dateEnd 
        :dateStart = bid.dateStart
        :currency = bid.currency
        :sold = bid.sold />

      </div>
      <!-- <div class="row">
        <div class="col-lg-12">
          <ul class="pagination">
            <li><a class="is_active" href="#">1</a></li>
            <li><a href="#">2</a></li>
            <li><a href="#">3</a></li>
            <li><a href="#">>></a></li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import BidPreview from '../components/BidPreview.vue'
import axios from 'axios'

export default{
  components: {
    BidPreview
  },

  data(){
    return{
      bids_actual: [],
      // bids_soon: [],
      bids_history: [],
      filter_active: 1
    }
  },
  beforeMount(){
    let getParam = window.location.search.replace( '?', ''); 
    switch(getParam){
      case 'buy_now':
        this.filter_active = 2;
        break;

      case 'actual':
        this.filter_active = 1;
        break;
      
      case 'history':
        this.filter_active = 3;
        break;
    }

    axios.post('https://d2auction.ru/api.php', {
    action: 'get_bids',
    type: 'Актуальные'
  }).then((response)=>{
    console.log(response)
    this.bids_actual = response.data
    this.bids_actual.forEach(bid => {
      if(bid.bets !== null){
            bid.price = JSON.parse(bid.bets)[0].amount
          }
          else{
            bid.price = bid.betStart
          }
    });
    
  })

  // if(this.$store.state.user_info.deposit == 1){
  //   axios.post('https://d2auction.ru/api.php', {
  //     action: 'get_bids',
  //     type: 'Запланированные'
  //   }).then((response)=>{
  //     console.log(response)
  //     this.bids_soon = response.data
      
  //   })
  // }

  axios.post('https://d2auction.ru/api.php', {
    action: 'get_bids',
    type: 'Прошедшие'
  }).then((response)=>{
    console.log(response)
    this.bids_history = response.data
    this.bids_history.forEach(bid => {
      if(bid.bets !== null  && bid.buynow == 0){
        bid.price = JSON.parse(bid.bets)[0].amount
      }
      else if (bid.bets == null && bid.buynow == 0){
        bid.price = bid.betStart
      }
    });
  })

  }
}
</script>

<style lang="scss">
.filter_active{
  background-color: #5b686d !important;
}

.filter_active:hover{
  color: #fff !important;
}

.properties-filter a{
  cursor: pointer;
}
</style>