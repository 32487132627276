import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '../views/MainPage.vue'
import BidsListPage from '../views/BidsListPage.vue'
import BidPage from '../views/BidPage.vue'
import AdminPage from '../views/AdminPage.vue'
import LkPage from '../views/LkPage.vue'

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainPage
  },
  {
    path: '/bids',
    name: 'bids',
    component: BidsListPage,
  },
  {
    path: '/bids/:id',
    component: BidPage,
    props: true
  },
  {
    path: '/bid_example',
    name: 'bid_example',
    component: BidPage
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPage
  },
  {
    path: '/lk',
    name: 'lk',
    component: LkPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
