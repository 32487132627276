<template>
    <div class="modal fade" id="outDepositModal" tabindex="-1" aria-labelledby="outDepositModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="outDepositModalLabel">Вывести депозит</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body d-flex">
          <form class="w-75 mx-auto my-2">
            <input type="text" class="form-control mt-1" v-model="outDepositCard" placeholder="Номер карты">
            <input type="text" class="form-control mt-3" v-model="outDepositBank" placeholder="Банк">
            <input type="text" class="form-control mt-3" v-model="outDepositName" placeholder="ФИО получателя">
            <div class="main-button d-flex flex-column">
              <a class="btn mt-3 mx-auto" @click="outDeposit">Вывести депозит</a>
            </div>
            
          </form>
        </div>
      </div>
    </div>
  </div>

    <div class="container">
        <div class="row" >
        <div class="lk-header d-flex justify-content-center my-5 col-12">
            <h2 class="text-center my-auto" style="line-height: 1;">Личный кабинет {{ user_info.login }}</h2>
            <button @click="exitUser" class="btn btn-sm btn-danger ms-3" style="height: fit-content;">Выйти</button>
        </div>
        <div class="col-12" v-if="$store.state.user_info.login !== 'admin'">
            <div class="lk-profile py-5 text-center">
                <h3 class="mb-3">Профиль</h3>
                <input type="text" :placeholder="user_info.name" class="form-control w-auto mb-2 mx-auto" v-model="nameUpdate">
                
                <input type="text" :placeholder="user_info.email" class="form-control w-auto mb-2 mx-auto" v-model="emailUpdate">
                
                <input type="text" :placeholder="user_info.phone" class="form-control w-auto mb-2 mx-auto" v-model="phoneUpdate">
                
                <input type="password" placeholder="Сменить пароль" class="form-control w-auto mb-2 mx-auto" v-model="passwordUpdate">
                
                <button class="btn btn-secondary" @click="updateProfile">Изменить данные</button>
            </div>
        </div>

        <!-- <div class="col-12">
            <div class="lk-deposit my-5 text-center">
                <h3 class="mb-3">Депозит</h3>
                <h3 class="mb-3" v-if="user_info.deposit==0">0 ₽</h3>
                <h3 class="mb-3" v-if="user_info.deposit==1">10000 ₽</h3>
                <form method="POST" action="https://yoomoney.ru/quickpay/confirm">
                    <input type="hidden" name="receiver" value="4100118487848402"/>
                    <input type="hidden" name="label" :value="user_info.login"/>
                    <input type="hidden" name="quickpay-form" value="button"/>
                    <input type="hidden" name="sum" value="10000" data-type="number"/>
                    <input type="hidden" name="paymentType" value="AC"/>
                    <input type="hidden" name="successURL" value="https://d2auction.ru/lk">
                    <button type="submit" class="btn btn-success mb-2" v-if="user_info.deposit == 0">Внести депозит</button>
                </form>
                <button class="btn btn-danger mb-2" v-if="user_info.deposit == 1" data-bs-toggle="modal" data-bs-target="#outDepositModal">Вывести депозит</button>
                <span style="font-size: 0.7em; display: block;">*Депозит в размере 10 000 ₽ необходим для возможности использования полного функционала платформы <br>(совершение ставок, добавление в избранное, просмотр истории ставок и т.д.)</span>
            </div>
        </div> -->

        <div class="col-12 my-5" style="min-height: 50vh;">
            <h3 class="text-center mb-5">Избранное</h3>
            <p class="text-center" v-if="user_info.favourites == 'null' || user_info.deposit == 0">У вас нет лотов в избранном</p>
            <div class="row properties" v-else>
                <bid-preview v-for="bid in bids_favourites"
                    :type = bid.type
                    :title = bid.title
                    :price = bid.price
                    :description = '[JSON.parse(bid.params)["Объем"], JSON.parse(bid.params)["Год"], JSON.parse(bid.params)["Пробег"], JSON.parse(bid.params)["Город"]]'
                    :images = JSON.parse(bid.images)
                    :id = bid.id 
                    :dateEnd = bid.dateEnd 
                    :dateStart = bid.dateStart
                    :currency = bid.currency
                    :sold = bid.sold />
            </div>
        </div>

    </div>
    </div>
    
</template>

<script>
import BidPreview from '@/components/BidPreview.vue';
import axios from 'axios';

export default{
    components: {
        BidPreview
    },

    data(){
        return{
            user_info: this.$store.state.user_info,
            nameUpdate: '',
            emailUpdate: '',
            phoneUpdate: '',
            passwordUpdate: '',
            bids_favourites: [],
            outDepositCard: '',
            outDepositBank: '',
            outDepositName: ''
        }
    },
    methods:{
        exitUser(){
            this.$store.commit('exitUser')
            window.location.href='/'
        },
        updateProfile(){
            if(this.nameUpdate!== ''){
                this.$store.commit('updateName', this.nameUpdate)
                axios.post('https://d2auction.ru/updateUser.php', {
                    id: this.user_info.id,
                    name: this.nameUpdate
                })
            }

            if(this.emailUpdate!== ''){
                this.$store.commit('updateEmail', this.emailUpdate)
                axios.post('https://d2auction.ru/updateUser.php', {
                    id: this.user_info.id,
                    email: this.emailUpdate
                })
            }

            if(this.phoneUpdate !== ''){
                if(this.phoneUpdate.length>10 && this.phoneUpdate.length < 13){
                    this.$store.commit('updatePhone', this.phoneUpdate)
                    axios.post('https://d2auction.ru/updateUser.php', {
                        id: this.user_info.id,
                        phone: this.phoneUpdate
                    })
                }
                else{
                    alert('Номер телефона некорректный')
                }
            }
            
            if(this.passwordUpdate !== ''){
                if(this.passwordUpdate.length < 7){
                    alert('Пароль слишком короткий')
                }
                else{
                    this.$store.commit('updatePassword', this.passwordUpdate)
                    axios.post('https://d2auction.ru/updateUser.php', {
                        id: this.user_info.id,
                        password: this.passwordUpdate
                    })
                }
                
            }

            this.nameUpdate = ''
            this.emailUpdate = ''
            this.phoneUpdate = ''
            this.passwordUpdate = ''

            alert('Введенные данные обновлены')
        },

        outDeposit(){
            axios.post('https://d2auction.ru/outDeposit.php', {
                id: this.user_info.id,
                login: this.user_info.login,
                card: this.outDepositCard,
                bank: this.outDepositBank,
                name: this.outDepositName
            }).then((response)=>{
                if(response.data === 1){
                    alert('Заявка на вывод депозита оформлена')
                    this.$store.commit('outDeposit')
                    window.location.reload()
                } 
            })
        }
    },
    beforeMount(){

        let authData = {
          login: this.user_info.login,
          password: this.user_info.password
        }
        axios.post('https://d2auction.ru/auth.php', authData).then((response)=>{
          console.log(response)

          if(Number(response.data['id']) !== NaN){
            this.$store.commit('authUser', response.data)
          }
          else{
            alert(response.data)
          }
        })

        console.log(this.user_info)
        if(this.user_info.favourites !== 'null'){
            axios.post('https://d2auction.ru/api.php', {
            action: 'get_bids_favourites',
            id_list: this.$store.state.user_info.favourites
        }).then((response)=>{
            console.log(response)
            this.bids_favourites = response.data
                this.bids_favourites.forEach(bid => {
                    if(bid.type == 'Актуальные'){
                    if(bid.bets !== null){
                        bid.price = JSON.parse(bid.bets)[0].amount
                    }
                    else{
                        bid.price = bid.betStart
                    }
                }
                });
            
            
        })
        }
        
    }
}
</script>